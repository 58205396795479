@use "sass:map";

.radio-btn {
    background-color: $white;
    border: $border-width solid $border-color;
    border-radius: $border-radius-lg;
    text-align: center;
    width: 100%;

    &:hover {
        box-shadow: 0 0 0 3px lighten($primary, 25%);
    }

    &-label + &-label {
        margin-left: map.get($spacers, 2);
    }

    input[type="radio"]:checked + & {
        background-color: lighten($primary, 35%);
        border-color: $primary;
        color: $primary;
    }
}