@use "scheduleVariables" as scheduleVars;
@use "sass:math";

.schedule-weekly {
    &-calendar {
        display: flex;
        overflow-x: auto;
    }
    &-item {
        flex: 1 1 0;
        width: 0;
        transition: flex-basis 0.15s ease-in-out;

        &, &:focus {
            border-top: 0;
            border-bottom: 0;
            border-right: 0;
            outline: none;
        }
    }
    &-weekdays-section {
        display: flex;
        flex-direction: column;
        height: 100%;
    }
    &-weekdays-container {
        display: flex;
        border-top: scheduleVars.$schedule-border;
        border-left: scheduleVars.$schedule-border;
        border-right: scheduleVars.$schedule-border;
        border-top-right-radius: $border-radius;
        border-top-left-radius: $border-radius;
    }
    &-sections-container {
        background-color: $light;
        border: scheduleVars.$schedule-border;
        border-bottom-right-radius: $border-radius;
        border-bottom-left-radius: $border-radius;

        &.min-height {
            min-height: 300px;
        }
    }
    &-hours-list {
        list-style-type: none;
        padding-left: 0;

        &-item {
            padding: map-get($spacers, 1);
            font-size: $small-font-size;

            &:not(:first-child) {
                border-top: scheduleVars.$schedule-border;
            }
        }
    }
    &-shared {
        &-collapse-btn {
            width: 100%;
            background-color: transparent;
            text-align: center;
            border-top: 0;
            border-right: 0;
            border-left: 0;
            border-bottom: scheduleVars.$schedule-border;

            &-icon {
                transition: transform 0.15s ease-in-out;
                display: inline-block;
            }
            &.is-open {
                transform: translateY(0);

                &::before {
                    opacity: 0;
                }
            }

            &.is-open &-icon {
                transform: rotate(180deg);
            }

            &:focus {
                outline: none;
            }
        }

        &-inner {
            border-bottom: scheduleVars.$schedule-border;
            width: 100%;

            &.is-closed {
                position: relative;

                &::after {
                    bottom: 0;
                    content: '';
                    left: 0;
                    position: absolute;
                    right: 0;
                    background: linear-gradient(to top, $light, transparent);
                    height: 20px;
                }
            }
        }

        &-event {
            align-items: center;
            flex: 0 1;
            flex-basis: 100%;
            display: flex;

            &-inner {
                transition: box-shadow 0.15s ease-in-out;
                background-color: $white;
                color: $dark;
                border: scheduleVars.$schedule-border;
                border-radius: $border-radius-lg;
                box-shadow: $box-shadow-sm;
                cursor: pointer;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
                padding: 0 map-get($spacers, 1);
                margin-right: map-get($spacers, 2);
                margin-left: map-get($spacers, 2);
                margin-bottom: map-get($spacers, 1);
                flex-basis: 100%;
                border-left: 0.2rem solid $primary;
                border-right: 0.2rem solid $primary;

                &:hover {
                    box-shadow: $box-shadow;
                }


            }

            &.is-continuing &-inner {
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
                border-left: none;
                margin-left: 0;
            }

            &.is-exceeding &-inner {
                border-right: none;
                margin-right: 0;
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
            }
            &.is-offset::before {
                flex-shrink: 0;
                content: '';
                display: block;
            }
            &.is-offset.is-offset {
                @each $nb, $width in scheduleVars.$event-widths {
                    &-#{$nb}::before {
                        flex-basis: $width;
                    }
                }
            }
            &.duration {
                @each $nb, $width in scheduleVars.$event-widths {
                    &-#{$nb} .schedule-weekly-shared-event-inner {
                        flex-basis: $width;
                    }
                }
            }
        }
        &-days {
            border-bottom: $border-width solid $border-color;;
            display: flex;
            flex-wrap: wrap;
            padding: map-get($spacers, 2) 0;
        }
    }
    &-days {
        background-color: $white;
        border-bottom: $border-width solid $border-color;
        flex: 1 1 0px;
        text-align: center;

        &:hover {
            cursor: pointer;
        }

        &-container {
            display: flex;
            flex-basis: 100%;
            overflow: hidden;
        }
    }
    &-day-nb {
        font-size: $h5-font-size;
        padding-bottom: map-get($spacers, 2);
        display: block;

        .is-selected & {
            font-weight: bold;
        }

        .is-today & {
            color: $primary;
        }
    }
    &-weekday {
        padding-top: map-get($spacers, 2);
        font-size: $font-size-sm;
        color: $text-muted;
        font-weight: 400;
    }
    &-events-container {
        padding: map-get($spacers, 2);
    }
}

/* MEDIA QUERY TABLET DESKTOP */
@include media-breakpoint-up(lg) {
    .schedule-weekly {
        &-calendar {
            min-height: 33vh;
        }
    }
}

.spdle-calendar-weekly {
    // ----- Week variables ----- //
    $week-grid-layout : repeat(7, 1fr);
    $main-grid-layout : scheduleVars.$schedule-first-column-width $week-grid-layout;
    // ----- End Week variables ----- //

    border: scheduleVars.$schedule-border;
    border-radius: $border-radius-lg;
    display: grid;
    grid-template-columns: $main-grid-layout;
    justify-content: stretch;

    &__inner {
        display: grid;
        grid-template-columns: $main-grid-layout;
        grid-column: 1 / 9;
        position: relative;

        &::after {
            background-image: linear-gradient(to right, $spordle 33%, rgba(255,255,255,0) 0%);
            background-position: bottom;
            background-size: 4px 2px;
            background-repeat: repeat-x;
            content: '';
            height: 1px;
            left: math.div(scheduleVars.$schedule-margin, 2);
            right: math.div(scheduleVars.$schedule-margin, 2);
            position: absolute;
            z-index: 50;
        }

        &::before {
            background-color: $spordle;
            border-radius: 1000px;
            content: '';
            height: 8px;
            left: 0;
            position: absolute;
            transform: translateY(-50%);
            width: 8px;
            z-index: 50;
        }
    }

    &__header {
        border-bottom: scheduleVars.$schedule-border;
        display: grid;
        grid-column: 1 / 9;
        grid-template-columns: $main-grid-layout;
        justify-content: stretch;

        &-day {
            border-left: scheduleVars.$schedule-border;
            cursor: pointer;
            text-align: center;

            &:hover &-nb {
                color: $primary;
            }

            &-name {
                color: $text-muted;
                font-size: $font-size-sm;
                font-weight: 400;
                padding-top: map-get($spacers, 2);

            }

            &-nb {
                display: block;
                font-size: $h5-font-size;
                padding-bottom: map-get($spacers, 2);

                .is-selected & {
                    font-weight: bold;
                }

                .is-today & {
                    color: $primary;
                }
            }
        }

        &-timezone {
            align-items: flex-end;
            display: flex;
            font-size: $small-font-size;
            justify-content: flex-end;
            padding: scheduleVars.$schedule-margin;
            text-align: right;
        }
    }

    &__shared {
        background-color: $light;
        border-left: scheduleVars.$schedule-border;
        display: grid;
        font-size: scheduleVars.$schedule-top-row-font-size;
        grid-auto-flow: row dense;
        grid-auto-rows: scheduleVars.$schedule-top-row-height;
        grid-column: 2 / 9;
        grid-template-columns: $week-grid-layout;
        height: 100%;
        line-height: 1;
        padding: scheduleVars.$schedule-margin 0;
        transition: max-height 0.35s ease-in-out;

        &.is-collapse {
            max-height: scheduleVars.$schedule-top-row-height * 4 + (scheduleVars.$schedule-margin * 2);
        }

        &-container {
            display: none;
            grid-column: 1 / 9;
            grid-template-columns: $main-grid-layout;
            overflow: hidden;

            @include media-breakpoint-up(md) {
                display: grid;
            }
        }

        &-event {
            grid-row-end: span 1;

            @for $i from 1 through 7 {
                &.start-#{$i} {
                    grid-column-start: #{$i};
                }
                &.end-#{$i} {
                    grid-column-end: span $i;
                }
            }
        }

        &-expand {
            align-items: flex-end;
            display: flex;
            justify-content: flex-end;
            padding: scheduleVars.$schedule-margin;

            &-btn {
                background-color: #fff;
                border: scheduleVars.$schedule-border;
                border-radius: 3px;
                box-shadow: $box-shadow-sm;
                color: $primary;
                padding: 3px;
                font-size: $small-font-size;
                padding: scheduleVars.$schedule-margin;
                transition: background-color 0.15s ease-in-out, transform 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
                width: 100%;

                &:hover {
                    background-color: $light;
                    box-shadow: $box-shadow;
                    transform: translateY(-1px);
                }

                &:focus {
                    outline: none;
                }
            }

            &-icon {
                display: inline-block;
                font-size: 0.85rem;
                @include scheduleVars.baseTransition('transform');

                &.is-extended {
                    transform: rotate(180deg);
                }
            }
        }
    }
    &__hours-list {
        list-style-type: none;
        padding-left: 0;
        text-align: right;
        grid-column: 1 / 1;
        margin-bottom: 0;
        display: grid;
        grid-template-rows: repeat(24, 35px);
        align-items: stretch;
        position: relative;

        & li {
            border-top: scheduleVars.$schedule-border;
            font-size: $small-font-size;
            padding: scheduleVars.$schedule-margin;
            white-space: nowrap;
        }
    }
}