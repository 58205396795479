$strokeWidth: 15px;

.Circle,
.Checkmark,
.Error1,
.Error2 {
    fill:none;
    stroke-miterlimit:10;
    // stroke-width:$strokeWidth;
    stroke: currentcolor;
}

.Checkmark,
.Error1,
.Error2 {
    stroke-dasharray: 150;
    stroke-dashoffset: 150;
}

.Svg {
    margin: auto;
    display: block;
}

.Circle {
    stroke-dasharray: 700;
    stroke-dashoffset: 700;

    :global(.animate) & {
        animation-name: circleDraw;
        animation-duration: 1s;
        animation-fill-mode: forwards;
    }
}

.Checkmark {
    :global(.animate) & {
        animation-name: checkmarkOrErrorDraw;
        animation-duration: 0.50s;
        animation-fill-mode: forwards;
        animation-delay: 0.40s;
    }
}

.Error1 {
    :global(.animate) & {
        animation-name: checkmarkOrErrorDraw;
        animation-duration: 0.50s;
        animation-fill-mode: forwards;
        animation-delay: 0.40s;
    }
}

.Error2 {
    :global(.animate) & {
        animation-name: checkmarkOrErrorDraw;
        animation-duration: 0.50s;
        animation-fill-mode: forwards;
        animation-delay: 0.50s;
    }
}

.Lock {
    display: inline-block;
    background-clip: content-box;
    height: 50%;
    width: 100%;
    background-color: inherit;
    border: 3px solid currentColor;
    border-radius: 5px;
    position: relative;
    -webkit-transition: all 0.1s ease-in-out;
    transition: all 0.1s ease-in-out;
    z-index: 1;

    &::after {
        content: "";
        display: block;
        background: currentColor;
        border-bottom: inherit;
        width: 50%;
        max-width: 7px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) rotate(90deg);
        -webkit-transition: all 0.1s ease-in-out;
        transition: all 0.1s ease-in-out;
    }

    &::before {
        content: "";
        display: block;
        width: 80%;
        height: 100%;
        bottom: 100%;
        margin-bottom: 2px;
        position: absolute;
        left: 50%;
        border: inherit;
        border-top-right-radius: 10000px;
        border-top-left-radius: 10000px;
        border-bottom: 0;
        transform: translateX(-50%);
        transform-origin: bottom left;
        -webkit-transition: all 0.1s ease-in-out;
        transition: all 0.1s ease-in-out;
        z-index: -1;
    }


    &.closeLock{
        &:hover::before {
            height: 85%;
        }

        animation-name: bounceLock;
        animation-delay: 0.35s;
        animation-duration: 0.2s;
        animation-fill-mode: forwards;
        animation-timing-function: ease-in-out;

        &::before {
            animation-name: closeLock;
            animation-duration: 0.5s;
            animation-fill-mode: forwards;
            animation-timing-function: ease-in-out;
        }
    }

    &.openLock{
        animation-name: rotateLock;
        animation-duration: 0.5s;
        animation-fill-mode: forwards;
        animation-timing-function: ease-in-out;

        &::before {
            animation-name: openLock;
            animation-duration: 0.5s;
            animation-fill-mode: forwards;
            animation-timing-function: ease-in-out;
        }
    }
}

@keyframes bounceLock {
    0% {
        transform: translateY(0px);
    }
    50% {
        transform: translateY(4px);
    }
    100% {
        transform: translateY(0px);
    }
}

@keyframes rotateLock {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(10deg);
    }
}

@keyframes openLock {
    0% {
        transform: translateX(-50%) rotate(0deg);
    }
    100% {
        transform: translateX(-50%) rotate(-30deg);
    }
}

@keyframes closeLock {
    0% {
        transform: translateX(-50%) rotate(-30deg);
        bottom: 102%;
    }
    80% {
        bottom: 102%;
        transform: translateX(-50%) rotate(0deg);
    }
    90% {
        bottom: 100%;
    }
    100% {
        bottom: 102%;
    }
}

.Unlock {
    transform: rotate(10deg);

}

// Animation
@keyframes circleDraw {
    from {
        stroke-dashoffset: 700;
    }
    to {
        stroke-dashoffset: 0;
    }
}

@keyframes checkmarkOrErrorDraw {
    from {
        stroke-dashoffset: 150;
    }
    to {
        stroke-dashoffset: 0;
    }
}


:export {
    stroke: $strokeWidth,
}