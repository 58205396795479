@use 'scheduleVariables' as scheduleVars;

@import './eventItem';
@import './eventDetails';
@import './scheduleMonthly';
@import './scheduleWeekly';

.spdle {
    &-calendar {
        &__events {
            background-color: $light;
            border-top: scheduleVars.$schedule-border;
            border-left: scheduleVars.$schedule-border;
            display: grid;
            grid-template-columns: 1fr;
            grid-template-rows: repeat(24, 35px);
            grid-auto-flow: column;

            // @include media-breakpoint-up(md) {
            //     display: grid;
            // }

            &--timeless {
                background-color: $light;
                border-left: scheduleVars.$schedule-border;
                grid-row: 1;
                grid-column: 1 / 9;
                margin-left: scheduleVars.$schedule-first-column-width;

                @include media-breakpoint-up(md) {
                    display: none;
                }
            }

            &.is-selected {
                display: none;
            }


            @include media-breakpoint-down(sm) {
                display: none;

                &.is-selected {
                    display: grid;
                    grid-column: 2 / 9;
                }
            }
        }

        &__single-event {
            background-color: white;
            border: scheduleVars.$schedule-border;
            border-left: 4px solid $primary;
            box-shadow: $box-shadow-sm;
            border-left: scheduleVars.$schedule-line-size solid $primary;
            border-radius: $border-radius-lg;
            cursor: pointer;
            margin: scheduleVars.$schedule-margin;
            overflow: hidden;
            padding-bottom: scheduleVars.$schedule-margin;
            padding-left: scheduleVars.$schedule-line-size + scheduleVars.$schedule-margin;
            padding-right: scheduleVars.$schedule-margin;
            padding-top: scheduleVars.$schedule-margin;
            position: relative;
            transition: box-shadow 0.15s ease-in-out;

            &::after {
                background: linear-gradient(to top, $white, transparent);
                bottom: 0;
                content: '';
                display: block;
                height: scheduleVars.$schedule-line-size + scheduleVars.$schedule-margin;
                left: scheduleVars.$schedule-line-size;
                position: absolute;
                right: 0;
            }

            &--compact {
                align-items: center;
                background-color: $white;
                border-left: scheduleVars.$schedule-line-size solid $primary;
                border-radius: $border-radius-lg;
                box-shadow: $box-shadow-sm;
                cursor: pointer;
                display: flex;
                margin: scheduleVars.$schedule-margin;
                padding-left: scheduleVars.$schedule-line-size;
                @include scheduleVars.truncate();

                &.is-exceeding {
                    margin-right: 0;
                    border-top-right-radius: 0;
                    border-bottom-right-radius: 0;
                }

                &.is-extending {
                    margin-left: 0;
                    border-top-left-radius: 0;
                    border-bottom-left-radius: 0;
                    border-left: 0;
                }

                &:hover {
                    box-shadow: $box-shadow;
                }

                &.is-all-day {
                    background-color: $primary;
                    color: $white;
                }
            }

            &.with-timeline {
                grid-column-start: 1;

                @for $i from 0 through 24 {
                    &.start-#{$i} {
                        grid-row-start: $i + 1;
                    }
                    &.end-#{$i} {
                        grid-row-end: $i + 1;
                    }
                }
            }

            &-location {
                font-size: 0.75rem;
                @include scheduleVars.truncate();
            }

            &-title {
                font-size: 0.8rem;
            }

            &:hover {
                box-shadow: $box-shadow;
                z-index: 10;
            }

        }
    }
}

.schedule {
    &-tab-content {
        min-height: 33vh;
    }
    &-nav {
        position: relative;
        background-color: $light;
        padding: map-get($spacers, 1);
        overflow:hidden;
        border-radius: $border-radius-lg;
        justify-content: space-between;

        & .nav-item {
            text-align: center;
            cursor: pointer;

            &.active {
                background-color: $white;
                box-shadow: $box-shadow;
                border-radius: $border-radius;
            }

            &.active .nav-link {
                color: $primary;
                position: relative;
                z-index: 10;
            }
        }

        & .nav-link {
            text-transform: uppercase;
            font-size: 0.9rem;
            color: $dark;
            padding-top: map-get($spacers, 1);
            padding-bottom: map-get($spacers, 1);
            transition: color 0.15s ease-in-out;
        }

    }
    &-datetime-container {
        position: relative;
        margin-bottom: map-get($spacers, 2);

        & .btn-today {
            margin-left: auto;
        }
    }
    &-filters-datetime {
        z-index: $zindex-popover;

        & .rdtPicker {
            border: $border-width solid $border-color;
            border-radius: $border-radius;
            box-shadow: $box-shadow-sm;
            background-color: $white;

            & .rdtToday {
                color: $primary;

                &::before {
                    display: none;
                }
            }

            & .rdtSwitch,
            & .rdtDay {
                border-radius: $border-radius;
                &:hover {
                    background-color: darken($light, 5%);
                }
            }
            & .rdtPrev,
            & .rdtNext {
                border-radius: 1000px;

                &:hover {
                    background-color: darken($light, 5%);
                }
                &::before {
                    font-size: 12px;
                    font-family: 'themify';
                    speak: none;
                    font-style: normal;
                    font-weight: normal;
                    font-feature-settings: normal;
                    font-variant: normal;
                    text-transform: none;
                    line-height: 1;
                    -webkit-font-smoothing: antialiased;
                    -moz-osx-font-smoothing: grayscale;
                }
                & > span {
                    display: none;
                }
            }
            & .rdtPrev::before {
                content: "\e64a";
            }
            & .rdtNext::before {
                content: "\e649";
            }
        }
    }
}

/* phone */
@include media-breakpoint-down(sm) {
    .schedule {
        &-datetime-container {
            & .btn-today {
                order: 1;
            }

            & .btn-prev {
                order: 2;
            }

            & .btn-next {
                order: 3;
            }
        }
    }
}
