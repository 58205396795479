@use "sass:math";

.vault {
    &-add-card {
        height: 100%;
        width: 100%;
        min-height: 125px;
        border: 0;
        color: $primary;
        border: $border-width dashed $primary;
        border-radius: $border-radius-lg * 2;
        font-size: $font-size-lg;
        background-color: transparent;
        transition: box-shadow 0.15s ease-in-out, transform 0.15s ease-in-out;

        &:hover {
            transform: translateY(-5px);
            box-shadow: $box-shadow;
        }

        &:focus {
            outline: none;
        }
    }
    &-card {
        @each $key, $color in $theme-colors {
            &-#{$key} {
                background: linear-gradient(to right, darken($color, 15%), $color);
                border-radius: $border-radius-lg * 2;
                box-shadow: $box-shadow;
                color: $light;
                padding: $spacer;
                position: relative;
            }
        }

        &-edit {
            background: transparent;
            border: 0;
            padding: $spacer;
            color: inherit;
            margin-top: -$spacer;
            margin-right: -$spacer;

            &:focus {
                outline: none;
            }

            &:hover {
                animation-timing-function: linear;
                animation-fill-mode: forwards;
                animation-iteration-count: infinite;
                animation-duration: 1.5s;
                animation-name: spin;
                color: $white;
                background-color: transparent;
            }

            @keyframes spin {
                0% {
                    transform: rotateZ(0);
                }
                50% {
                    transform: rotateZ(180deg);
                }
                100% {
                    transform: rotateZ(360deg);
                }
            }
        }

        &-name {
            font-weight: $font-weight-medium;
            margin-bottom: map-get($spacers,2);
        }

        &-label {
            font-size: math.div($font-size-sm, 1.5);
        }

        &-number {
            line-height: 1;
            font-size: 1.5rem;
        }

        &-top {
            display: flex;
            justify-content: space-between;
            align-items: flex-start;

            &::before {
                content: '';
                display: block;
                background: linear-gradient(to right, darken($light,20%), $light);
                height: 30px;
                width: 30px;
                border-radius: $border-radius-lg * 1.5;
                margin-bottom: map-get($spacers, 2);
            }
        }

        &-bottom {
            display: flex;
            align-items: flex-end;
            justify-content: space-between;
        }

        &-brand {
            display: block;
            max-width: 40px;
        }
    }
}