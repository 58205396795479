.event-item {
    margin-bottom: $spacer;

    &-weekly {
        font-size: $font-size-sm;
        margin-bottom: map-get($spacers, 2);

        .event-item-name {
            font-size: 14px;
        }

        .event-item-type,
        .event-item-time-separator {
            display: none;
        }
        .event-item-time {
            font-size: $font-size-sm;
        }
        .event-item-location {
            color: $text-muted;
            font-size: 12px;
            margin-bottom: map-get($spacers, 2);
        }
    }
    &-type {
        color: $text-muted;
        font-size: $font-size-sm;
    }
    &-inner {
        box-shadow: $box-shadow-sm;
        background-color: $white;
        border-radius: $border-radius-lg;
        border: $border-width solid $border-color;
        border-left: 0.2rem solid $primary;
        cursor: pointer;
        padding-bottom: $spacer;
        padding-top: $spacer;
        transition: box-shadow 0.15s ease-in-out;

        &:hover {
            box-shadow: $box-shadow;
        }
        .event-item-name {
            font-size: 14px;
        }
        .event-item-weekly & {
            padding-top: map-get($spacers, 2);
            padding-bottom: map-get($spacers, 2);
        }
    }
    &-info {
        display: flex;
        justify-content: space-between;

        .event-item-weekly & {
            flex-direction: column-reverse;
            margin-bottom: map-get($spacers, 2);
        }
    }

    &-info,
    &-location {
        padding-left: $spacer;
        padding-right: $spacer;

        .event-item-weekly & {
            padding-left: map-get($spacers, 2);
            padding-right: map-get($spacers, 2);
        }
    }

    &-name {
        padding: 5px $spacer;
        font-weight: 500;
        font-size: $h4-font-size;
        margin-bottom: -5px;
        margin-top: -5px;
        word-break: break-all;

        .event-item-weekly & {
            border: 0;
            padding-left: map-get($spacers, 2);
            padding-right: map-get($spacers, 2);
        }
    }

    &-member {
        &s {
            display: flex;
            padding-left: $spacer - 0.2rem;

            .event-item-weekly & {
                padding-left: map-get($spacers, 2);
                padding-right: map-get($spacers, 2);
            }
        }

        &-pill {
            border: 3px solid $white;
            box-shadow: none;
            margin-right: - map-get($spacers, 2);
            transition: margin 0.35s ease-in-out;
        }

        &-container {
            align-items:center;
            display: flex;
            margin-left: -3px; // Border width

            &.is-hoverable:hover {
                & .event-item-member-pill {
                    margin-right: 0;
                }
                & .event-item-member-info {
                    margin-left: map-get($spacers, 1);
                    margin-right: map-get($spacers, 2);
                    max-width: 100px;
                    opacity: 1;
                }
            }
            &.is-not-hoverable:hover {
                & .event-item-member-pill {
                    margin-right: 0;
                }
                & .event-item-member-info {
                    margin-left: map-get($spacers, 1);
                    margin-right: map-get($spacers, 2);
                    max-width: 100px;
                    opacity: 1;
                }
            }

        }

        &-info {
            font-size: $small-font-size;
            font-weight: $font-weight-bold;
            max-width: 0;
            opacity: 0;
            overflow: hidden;
            transition: max-width 0.35s ease-in-out, opacity 0.35s ease-in-out, margin 0.35s ease-in-out;
            white-space: nowrap;
        }
    }
}