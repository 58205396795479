.payments {
    &-upcoming {
        &-price {
            color: $dark;
            font-size: $h1-font-size;
            font-weight: $font-weight-bold;
        }
        &-date {
            color: $text-muted;
        }
    }
}