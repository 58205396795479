$border: $border-width solid $input-border-color;
$spacingMargin: 5px;
$lineHeight: 1px;

.spordleSelect {
    &-placeholder {
        color: $input-placeholder-color;
    }
    &-option-first {
        margin-top: $spacingMargin;
    }
    &-option-last {
        margin-bottom: $spacingMargin;
    }
    &-li-multi &-option {
        margin-bottom: $spacingMargin*2+$lineHeight;

        &::after {
            background-color: $light;
            bottom: $spacingMargin;
            content: '';
            display: block;
            position: absolute;
            left: 0;
            right: 0;
            width: 100%;
            height: $lineHeight;
        }
    }
    &-li {
        position: relative;
    }
    &-li-group + &-li-opt &-option,
    &-li:not(.spordleSelect-li-multi) + &-li-start-group &-optgroup {
        margin-top: $spacingMargin*2+$lineHeight;

        &::before {
            content: '';
            display: block;
            background-color: $light;
            position: absolute;
            left: 0;
            right: 0;
            top: $spacingMargin;
            width: 100%;
            height: $lineHeight;
        }
    }
    &-option {
        transition: background-color 0.1s ease-in-out;

        &.spordleSelect-option-active{
            background-color: $light;

            &[data-selected=true] {
                background-color: lighten($primary, 10%);
            }
        }
        &[data-selected=true] {
            background-color: $primary;
            color: $white;
        }
        &-container {
            border: $border;
            border-radius: $border-radius-lg;
            box-shadow: $box-shadow-sm;
            @include border-radius($input-border-radius, 0);
        }
    }
    &-optgroup {
        color: $input-placeholder-color;
    }
    &-input {
        &::placeholder {
            color: $input-placeholder-color;
            opacity: 1; // For firefox
        }
        &::-ms-input-placeholder {
            color: $input-placeholder-color;
            opacity: 1; // For firefox
        }
        &-container {
            &:disabled,
            &[data-disabled=true] {
                background-color: $input-disabled-bg;
            }
        }
    }
    &-icon {
        color: rgba($border-color, 0.25);
        transition: color 0.15s ease-in-out;
    }
    &-icon:hover {
        color: rgba($border-color, 0.5);
    }
    &-clear-btn:focus, &-clear-btn:active {
        color: darken($primary, 30%);
    }
    &-icons &-chevron {
        border-left: $border;
    }
}