.schedule-monthly {
    &-calendar {
        table-layout: fixed;
        width: 100%;
        overflow: hidden;

        &-container {
            background-color: $light;
            border: $border-width solid $border-color;
            border-radius: $border-radius;
        }
    }
    &-nb {
        font-size: $h5-font-size;
        line-height: 1;

        &.is-not-current-month {
            opacity: 0.5;
        }
    }
    &-item {
        position: relative;
        text-align: center;
        vertical-align: middle;
        background-color: $light;

        &:after {
            content: '';
            display: block;
            margin-top: 100%;
        }

        &-inner {
            cursor: pointer;
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            margin: auto;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            border-radius: 1000px;
            width: 70%;
            height: 70%;
            transition: background-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

            .is-today & {
                color: $primary;
            }

            .is-selected & {
                border: $border-width solid $border-color;
                background-color: $white;
                box-shadow: $box-shadow-sm;
                font-weight: bold;
            }

            &:hover {
                background-color: $white;
                box-shadow: $box-shadow-sm;
            }
        }
    }
    &-days {
        overflow-x: hidden;
        white-space: nowrap;
        padding: map-get($spacers, 2);
        font-size: $font-size-sm;
        color: $text-muted;
        text-align: center;
        background-color: $white;
        border-bottom: $border-width solid $border-color;
        font-weight: normal;

        &::before {
            display: none;
        }
    }
    &-events {
        display: flex;
    }
    &-plus {
        height: 5px;
        width: 5px;
        border-radius: 5px;
        flex-shrink: 0;
        margin-left: 1px;
        margin-right: 1px;
        margin-bottom: -5px;
        font-size: 10px;
        line-height: 0.5;
        color: $primary;
    }
    &-dot {
        height: 5px;
        width: 5px;
        border-radius: 5px;
        flex-shrink: 0;
        margin-left: 1px;
        margin-right: 1px;
        margin-bottom: -5px;
        font-size: 5px;
        line-height: 0;

        &.practice {
            background-color: blue;
        }
        &.game {
            background-color: red;
        }
    }
}