.vertical-timeline {
    &::before {
        box-shadow: $box-shadow-sm;
        border-left: $border-width solid $border-color;
        border-right: $border-width solid $border-color;
        border-bottom: $border-width solid $border-color;
        width: 6px;
    }
    &-element {
        &-content {
            border: $border-width solid $border-color;
            box-shadow: $box-shadow-sm !important;
        }
        &-icon {
            box-shadow: $box-shadow-sm;
            background-color: $white;
            border: $border-width solid $border-color;
        }
    }
}