@use "sass:math";

$event-widths: (
    "1" : math.div(100%,7),
    "2" : math.div(100%,7) * 2,
    "3" : math.div(100%,7) * 3,
    "4" : math.div(100%,7) * 4,
    "5" : math.div(100%,7) * 5,
    "6" : math.div(100%,7) * 6,
    "7" : 100%,
);

$schedule-line-size: 0.2rem;
$schedule-margin: 0.2rem;
$schedule-border: 1px solid rgb(225, 225, 225);
$schedule-top-row-font-size: 0.75rem;
$schedule-first-column-width: 60px;
$schedule-top-row-height: $schedule-margin * 4 + $schedule-top-row-font-size;

@mixin baseTransition($select) {
    transition: #{$select} 0.15s ease-in-out;
}

@mixin truncate() {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}