.custom-alert-border{
    @each $color, $value in $theme-colors {
        &-#{$color} {
            border-width: 2px;
            border-style: solid;
            border-color: rgba($value, $alpha: 0.5);
            display: flex;
            align-items: center;
            padding: map-get($spacers, 2);
        }
    }
}