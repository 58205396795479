@each $colorKey, $colorGradient in $clinic-gradients {
    $colorDark: map-get($colorGradient, dark); // Default color
    $colorLight: map-get($colorGradient, light);

    .bg-#{$colorKey} {
        background-color: $colorDark !important;
    }

    .bg-clinic-#{$colorKey} {
        background-color: $colorDark !important;
    }

    .bg-gradient-clinic-#{$colorKey} {
        background: linear-gradient(to right, $colorDark, $colorLight);
    }

    .text-clinic-#{$colorKey} {
        color: $colorDark !important;
    }

    .border-clinic-#{$colorKey} {
        border-color: $colorDark !important;
    }

    .fill-clinic-#{$colorKey} .cls-1, .fill-clinic-#{$colorKey} .cls-2 {
        fill: $colorDark !important
    }

    .fill-clinic-#{$colorKey} .cls-2 {
        opacity: 0.25
    }

    .btn-clinic-#{$colorKey} {
        @include button-variant($colorDark, $colorLight);
    }

    .btn-outline-clinic-#{$colorKey} {
        @include button-outline-variant($colorDark);
    }

    .color-clinic-#{$colorKey} {
        // radio & checkboxes
        &.custom-control .custom-control-input {
            &:checked~.custom-control-label::before {
                color: $colorDark;
                border-color: $colorDark;
                @include gradient-bg($colorDark);
            }

            &:focus~.custom-control-label::before {
                box-shadow: 0 0 0 $input-btn-focus-width rgba($colorDark, .25);
            }

            &:focus:not(:checked)~.custom-control-label::before {
                border-color: lighten($colorDark, 25%);
            }

            &:not(:disabled):active~.custom-control-label::before {
                background-color: lighten($colorDark, 35%);
                border-color: lighten($colorDark, 35%);
            }

            &:disabled {
                ~.custom-control-label {
                    color: $custom-control-label-disabled-color;

                    &::before {
                        background-color: $custom-control-indicator-disabled-bg;
                    }
                }
            }
        }

        // inputs
        &.form-control:focus {
            border-color: lighten($colorDark, 25%);
            box-shadow: 0 0 0 $input-btn-focus-width rgba($colorDark, .25);
        }

        // react select
        & .form-control-select {
            &__control{
                &:hover {
                    border-color: lighten($colorDark, 25%);
                }
                &--is-focused {
                    border-color: lighten($colorDark, 25%);
                    box-shadow: 0 0 0 $input-btn-focus-width rgba($colorDark, .25);
                }
            }
            &__option {
                &--is-focused,
                &:focus, &:active {
                    background-color: lighten($colorDark, 50%);
                }
                &--is-selected {
                    background-color: lighten($colorDark, 15%);
                }
                & .custom-control-input:disabled:checked ~ .custom-control-label::before {
                    background-color: rgba($colorDark, 0.5);
                    border-color: rgba($colorDark, 0.5);
                }
                & .custom-control-input:checked ~ .custom-control-label::before {
                    background-color: $colorDark;
                    border-color: $colorDark;
                }
            }
        }
    }
}
