.stac {
    &-btn {
        min-width: 100px;
    }
    &-spordle-account {
        height: 24px;
    }
    &-container {
        display: flex;
        align-items: center;
        position: fixed;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        background-color: $light;
        z-index: 2000;

        &-inner {
            @include media-breakpoint-down(sm) {
                max-width: 400px;
            }
        }
    }
    &-inner {
        height: 40vh;
        overflow-y: scroll;
    }
}