@import '~@spordle/ui-kit/assets/default/scss/all/bootstrap/functions';
@import '~@spordle/ui-kit/assets/default/scss/all/bootstrap/variables';
@import '~@spordle/ui-kit/assets/default/scss/all/custom/variables';
@import 'variables';
@import '~@spordle/ui-kit/assets/default/scss/all/bootstrap/mixins';
@import '~@spordle/ui-kit/assets/default/scss/all/bootstrap/buttons';

:global(.btn).btn-hcr{
    @include button-variant($primary, $primary)
}

@include media-breakpoint-up(md) {
    .hcr-background {
        background-image: url('../../../images/auth/hcr/auth_background.jpg');
        background-size: cover;
        background-repeat: no-repeat;
        background-attachment: fixed;
        background-position: right;

        .signup-btn {
            color: $primary;

            &:hover {
                color: darken($primary, 10%);
            }
        }

        :global(.btn).lang-btn {
            @include button-outline-variant($primary);
        }
    }

    .hcr-background-play {
        background-image: url('../../../images/auth/hcr/auth_background_play.jpg');
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        background-attachment: fixed;
        color: $light;
    }

    .hcr-background-clinic {
        background-image: url('../../../images/auth/hcr/auth_background_clinic.jpg');
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        background-attachment: fixed;
        color: $light;
    }

    .signup-btn {
        color: $light;
        line-height: 1.2;
        border-bottom: 1px solid;

        &:hover {
            color: $gray-500;
        }
    }

    :global(.btn).lang-btn {
        @include button-outline-variant($light);
        border: none;
    }
}