.line-height-1 {
    line-height: 1;
}

.text-light {
    @each $key, $color in $theme-colors {
        &-#{$key} {
            color: lighten($color, 20%) !important;
        }
    }
}