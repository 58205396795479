@media screen and (max-width: 576px) {
    .Choices {
        &Label {
            position: relative;
            z-index: 2;
        }
        &Container {
            position: relative;
        }
        &Row {
            padding-top: 3px;
            padding-bottom: 3px;
            overflow-x: auto;
            padding-right: 50px;

            &::after {
                width: 50px;
                top: 0;
                bottom: 0;
                right: 0;
                background: linear-gradient(to left, #ffffff, transparent);
                content: "";
                position: absolute;
                pointer-events: none;
                z-index: 1;
            }
        }
    }
}