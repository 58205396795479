// hardcoded, but controlled padding (hopefully)
$auth-padding-y: (map-get($spacers, 3) * 2) + ($btn-line-height * $btn-font-size) + ($btn-padding-y * 2);

.auth {
	&-wrapper {
		min-height: 100vh;
		position: relative;
		padding: $auth-padding-y 0;
		background-color: $light;
		display: flex;
		align-items: center;
	}
    &-container {
        max-width: 400px;
        padding-left: map-get($container-padding, 'xs');
        padding-right: map-get($container-padding, 'xs');
        margin-left: auto;
        margin-right: auto;
		flex-grow: 1;
    }
}