$transitionMs: 400;

.Card {
    transform: translateY(0);
    transition: transform 0.15s ease-in-out;

    &:hover {
        transform: translateY(-5px);
    }
}

:global(.collapsing).Collapse {
    transition: height #{$transitionMs}ms ease-in-out;
}

.Col {
    &Shrink {
        @media screen and (min-width: 992px) {
            display: none;
            // flex: 0 1 0;
            // min-width: 0;
            // overflow: hidden;
            // white-space: nowrap;
        }
    }

    &Grow {
        @media screen and (min-width: 992px) {
            flex-grow: 1;
            transition: all #{$transitionMs}ms ease-in-out;
        }
    }
}

:export {
    transitionMs: $transitionMs,
}