.inputAuth {
    position: relative;
    text-align: left;

    ::-webkit-input-placeholder {
        /* WebKit browsers */
         color: transparent;
    }
    :-moz-placeholder {
    /* Mozilla Firefox 4 to 18 */
        color: transparent;
    }
    ::-moz-placeholder {
        /* Mozilla Firefox 19+ */
        color: transparent;
    }
    :-ms-input-placeholder {
        /* Internet Explorer 10+ */
        color: transparent;
    }
    input::placeholder {
        color: transparent;
    }
    textarea::-webkit-input-placeholder {
        /* WebKit browsers */
        color: transparent;
    }
    textarea:-moz-placeholder {
        /* Mozilla Firefox 4 to 18 */
        color: transparent;
    }
    textarea::-moz-placeholder {
        /* Mozilla Firefox 19+ */
        color: transparent;
    }
    textarea:-ms-input-placeholder {
        /* Internet Explorer 10+ */
        color: transparent;
    }
    textarea::placeholder {
        color: transparent;
    }

    &-container {
        position: relative;
    }

    &-tooltip {
        & i {
            color: $text-muted;
            transition: color 0.15s ease-in-out;
        }
        &:hover i {
            color: $dark;
        }
    }

    &-showPsw {
        background: $white;
        color: $text-muted;
        cursor: pointer;
        display: inline-block;
        padding-left: map-get($spacers, 1);
        position: absolute;
        top: calc(50% - #{$inputAuth-label-fs}); // 50% - label's height
        right: $inputAuth-padding-x;
        opacity: 1;
        font-size: 1rem;
        line-height: 1.5;
        width: $inputAuth-icon-width;
        transition: opacity 0.15s ease-in-out, color 0.15s ease-in-out;
        vertical-align: middle;

        &.hide {
            opacity: 0;
        }

        &:hover {
            color: $dark;
        }

    }

    &-text,
    &-phoneInput {
        border: $inputAuth-border-width solid $inputAuth-border-color;
        border-radius: 4px;
        box-shadow: none;
        padding-left: $inputAuth-padding-x;
        padding-right: $inputAuth-padding-x;
        padding-top: calc(#{$inputAuth-padding-y} + #{$inputAuth-label-fs} + .25rem); // padding-top + label's height + margin
        padding-bottom: $inputAuth-padding-y;
        font-size: 1rem;
        line-height: 1.5;

        // This min height represents :
        // padding-y * 2 (top + bottom)
        // + label height
        // + label margin-bottom
        // + border width * 2 (top + bottom)
        min-height: 52px; // This is a fallback in case the line under fails
        min-height: calc(1.5em + (($inputAuth-padding-y * 2) + ($inputAuth-label-fs + .25rem)) + ($inputAuth-border-width * 2));

        &::-ms-reveal,
        &::-ms-clear {
            display: none;
        }

        // on focus styles
        &:focus {
            border-color: $primary;
        }

        &:not(.is-empty),
        &:focus {
            &~label.inputAuth-label {
                top: $inputAuth-padding-y;
                left: $inputAuth-padding-x;
                font-size: $inputAuth-label-fs;
                color: $dark;
            }
        }
    }

    &-text {
        width: 100%;
        padding-left: $inputAuth-padding-x;
        padding-right: $inputAuth-padding-x;
        padding-top: calc(#{$inputAuth-padding-y} + #{$inputAuth-label-fs} + .25rem); // padding-top + label's height + margin
        padding-bottom: $inputAuth-padding-y;
        background-color: #fff;
        transition: all 0.15s ease-in-out;

        &::-ms-reveal,
        &::-ms-clear {
            display: none;
        }

        // on focus styles
        &:focus {
            border-color: $primary;
        }

        &:not(.is-empty),
        &:focus {
            &~label.inputAuth-label {
                top: $inputAuth-padding-y;
                left: $inputAuth-padding-x;
                font-size: $inputAuth-label-fs;
                color: $dark;
            }
        }
    }

    &-label {
        position: absolute;
        left: $inputAuth-padding-x;
        top: calc(50% - #{$inputAuth-label-fs}); // 50% - label's height
        color: $text-muted;
        font-size: 1rem;
        line-height: 1.5;
        transition: all 0.15s ease-in-out;
        pointer-events: none;
        z-index: 1;
    }

    &-phone {
        & .btn-group .dropdown-toggle {
            border-top-left-radius: 4px;
            border-bottom-left-radius: 4px;
            border-top: $inputAuth-border-width solid $inputAuth-border-color !important;
            border-left: $inputAuth-border-width solid $inputAuth-border-color !important;
            border-right: none !important;
            border-bottom: $inputAuth-border-width solid $inputAuth-border-color !important;
        }

        &Input {
            padding-left: $inputAuth-padding-x;
            padding-right: $inputAuth-padding-x;
            padding-top: $inputAuth-padding-y;
            padding-bottom: $inputAuth-padding-y;
        }

    }

    // has icon styles
    &.has-icon &-label {
        left: calc(#{$inputAuth-padding-x} + #{$inputAuth-icon-width});
    }

    i {
        position: absolute;
        top: calc(50% - #{$inputAuth-label-fs}); // 50% - label's height
        left: $inputAuth-padding-x;
        line-height: 1.5;
        width: $inputAuth-icon-width;
        display: inline-block;
        color: $text-muted;
        transition: opacity 0.15s ease-in-out;
        opacity: 1;
        vertical-align: middle;

    }

    &-text:not(.is-empty),
    &-text:focus {
        &~i {
            opacity: 0;
        }
    }
}

// react-select-control
.auth-select {
    .spordleSelect-input{
        top: 50%;
        transform: translateY(-50%);
    }
    .form-control {
        border-color: $inputAuth-border-color;
        border-radius: 4px;
        border-width: $inputAuth-border-width;
        font-size: 1rem;
        padding-left: $inputAuth-padding-x;

        // This min height represents :
        // padding-y * 2 (top + bottom)
        // + label height
        // + label margin-bottom
        // + border width * 2 (top + bottom)
        min-height: 52px; // This is a fallback in case the line under fails
        min-height: calc(1.5em + (($inputAuth-padding-y * 2) + ($inputAuth-label-fs + .25rem)) + ($inputAuth-border-width * 2));

        padding-bottom: $inputAuth-padding-y;
        padding-top: $inputAuth-padding-y;

        &:focus,
        &:focus-within {
            box-shadow: none;
            border-color: $primary;
        }
    }

    .spordleSelect-chevron {
        font-size: 20px;
        color: $text-muted;
    }

    .spordleSelect-option {
        padding-bottom: map-get($spacers, 2) * 1.5;
        padding-top: map-get($spacers, 2) * 1.5;
    }
}

// formik's error messages...
.error {
	color: $danger;
}
