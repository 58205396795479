.event-details {
    @include media-breakpoint-down(sm) {
        background-color: rgba(62, 62, 62, 0.4);
    }

    & .custom-modal-container {
        @include media-breakpoint-down(sm) {
            height: auto;
            position: absolute;
            bottom: 0;
            box-shadow: $box-shadow-lg;
            border: $border-width solid $border-color;
            border-top-left-radius: $border-radius-lg * 2;
            border-top-right-radius: $border-radius-lg * 2;
        }
    }
}