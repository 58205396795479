@import './variables';
@import '~@spordle/ui-kit/assets/default/scss/style.scss';

@import './components/badge';
@import './components/dot';
@import './components/fileUpload';
@import './components/invoice';
@import './components/header';
@import './components/headerNotifications';
@import './components/helpCenter';
@import './components/maintenance';
@import './components/overlayLoader';
@import './components/paymentMethod';
@import './components/termsOfUse';
@import './components/payments';
@import './components/sidePanel';
@import './components/spordleSelect';
@import './components/timeline';
@import './components/toast';
@import './components/userDisplay';
@import './components/userImg';
@import './components/vault';
@import './components/schedule/schedule';
@import './components/customAlert';
@import './utilities/btns';
@import './utilities/clinic';
@import './utilities/modal';
@import './utilities/text';
@import './utilities/svg';
@import './views/memberDocuments';
@import './views/memberContacts';


@import './auth/layout';
@import './auth/forms';

// quick patched pinfield's focus
// we might eventually add this style on every input:focus, in ui-kit
.a-reactPinField__input:focus {
    border-color: $primary;
    box-shadow: 0 0 0 3px rgba($primary, .3);
}

.modal:not(.show) .close {
    pointer-events: none;
}

.sidebar-nav ul .sidebar-item-header img {
    border-radius: 0;
    margin-right: 0;
    width: auto;
}

.rotate-180 {
    transform: rotate(180deg);
}

.editable-transparent {
    background-color: transparent;
}

.bg-primary-light {
    background-color: lighten($primary, 33%);
}

.flex-center {
    align-items:center !important;
    justify-content:center !important;
}

.date-picker-left .rdtPicker {
    right: 0px
}