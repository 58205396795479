@include media-breakpoint-down(xs) {
    .sidePanel{
        border-left: $modal-content-border-width solid $modal-content-border-color;
        border-bottom-left-radius: $modal-content-border-radius;
        border-top-left-radius: $modal-content-border-radius;
        right: -100%;
        transition: left 0.3s ease-in-out, right 0.3s ease-in-out;
        padding-top: 0;
        width: 95vw;
        z-index: $zindex-fixed + 3;

        @include box-shadow($modal-content-box-shadow-xs);

        &-backdrop {
            background-color: rgba(0,0,0, 0.5);
            z-index: $zindex-fixed + 2;
        }
    }
}