@import '~@spordle/ui-kit/assets/default/scss/all/bootstrap/functions';
@import '~@spordle/ui-kit/assets/default/scss/all/bootstrap/variables';
@import '~@spordle/ui-kit/assets/default/scss/all/custom/variables';

$fileViewerDark: #121519;

.Bar {
    left: 0;
    opacity: 0;
    padding: 1rem;
    position: fixed;
    right: 0;
    transition: opacity 0.5s ease-in-out, visibility 0.5s ease-in-out;
    z-index: 10;
    visibility: hidden;

    &.IsVisible,
    &:hover {
        opacity: 1;
        visibility: visible;
    }

    &Top {
        align-items: center;
        background: linear-gradient(to bottom, rgba($fileViewerDark,1), rgba($fileViewerDark,0));
        color: rgba($white, 0.65);
        display: flex;
        justify-content: space-between;
        top: 0;
        pointer-events: none;

        &FileInfo {
            margin-right: auto;
            pointer-events: initial;
        }

        &FileName {
            color: $white;
            font-weight: bold;
        }

        &Btns {
            white-space: nowrap;
            & button{
                pointer-events: initial;
            }
        }
    }

    &Bottom {
        background: linear-gradient(to top, rgba($fileViewerDark, 1), rgba($fileViewerDark,0));
        bottom: 0;
        text-align: center;
        pointer-events: none;
        & button{
            pointer-events: initial;
        }
    }
}

.Container {
    background-color: rgba($fileViewerDark,0.925);
    bottom: 0;
    left: 0;
    overflow: auto;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 999999;
}

.Pdf {
    &Page {
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 1rem;
    }
    &FirstPage {
        margin-top: 2rem;
    }
}

.Preview {
    display: inline-block;
    vertical-align: middle;
    flex-shrink: 0;
    margin: auto;
    max-width: none;
    position: relative;
    image-rendering: pixelated;

    &Img{
        user-select: none;
    }
    &Video{
        max-width: 100%;
        max-height: 100%;
    }
    &Container {
        display: flex;
        min-height: 100%;
        position: relative;
        width: 100% !important;

        &Zoom{
            min-height: 100%;
            position: relative;
            width: 100% !important;
            height: 100% !important;
        }

        &.WithText {
            min-width: min-content;
            user-select: initial;

            & :global(.react-transform-component) {
                width: 100%;
                height: auto;
            }
        }
    }
    &Text {
        white-space: pre;
        min-width: 100%;
    }
}

.Icon {
    font-size: 150px;
    line-height: 1;
}

.NoPreview {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
}

.Loading {
    animation-name: loadingDocAnimation;
    animation-timing-function: ease-in-out;
    animation-duration: 1.35s;
    animation-iteration-count: infinite;
    font-size: 200px;
}

@keyframes loadingDocAnimation {
    0% {
        opacity: 0.4;
    }
    50% {
        opacity: 0.95;
    }
    100% {
        opacity: 0.4;
    }
}